<template>
  <div id="dashboard-lom-president">
    <v-container fluid tag="section">
      <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
        <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
      </div>
    </v-container>
    <v-container v-if="StartupLoadingFlag">
      <v-row wrap>
        <v-col cols="12" md="4" v-for="n in 6" :key="n">
          <v-skeleton-loader
            v-bind="attrs"
            type="paragraph"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!StartupLoadingFlag">
      <v-row wrap>
        <v-col cols="12" md="12">
          <h2>{{ PageTitle }}</h2>
        </v-col>
      </v-row>
      <v-form ref="form2" lazy-validation>
        <v-container v-if="!StartupLoadingFlag">
          <v-row wrap>
            <v-col cols="12" md="3">
              <label>
                <h6>Area</h6>
              </label>
              <v-autocomplete
                @change="getZoneCodeOptions"
                :reduce="(option) => option.value"
                :loading="AreaCodeOptionsLoading"
                :items="AreaCodeOptions"
                :rules="AreaCodeRules"
                v-model="AreaCode"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <label>
                <h6>Zone</h6>
              </label>
              <v-autocomplete
                @change="getRegionCodeOptions"
                :reduce="(option) => option.value"
                :loading="ZoneCodeOptionsLoading"
                :items="ZoneCodeOptions"
                :rules="ZoneCodeRules"
                v-model="ZoneCode"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <label>
                <h6>Region</h6>
              </label>
              <v-autocomplete
                @change="getLomCodeOptions"
                :reduce="(option) => option.value"
                :loading="RegionCodeOptionsLoading"
                :items="RegionCodeOptions"
                :rules="RegionCodeRules"
                v-model="RegionCode"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <label>
                <h6>Lom</h6>
              </label>
              <v-autocomplete
                :reduce="(option) => option.value"
                :loading="LomCodeOptionsLoading"
                :items="LomCodeOptions"
                v-model="LomCode"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                @click.prevent="searchForm"
                :loading="SearchFlag"
                target="_blank"
                color="#8950FC"
                class="
                  btn btn-primary
                  font-size-h6
                  px-15
                  py-4
                  my-3
                  mr-3
                  white--text
                "
                >Search</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-container>
    <v-container>
      <v-row wrap>
        <v-col cols="12" md="6" align="center">
          <div :style="LomProfileStyle">
            <canvas id="LomProfileChart" style="height: 0"></canvas>
          </div>
        </v-col>

        <v-col cols="12" md="6" align="center">
          <div :style="LomMemberImageStyle">
            <canvas id="LomMemberImageChart" style="height: 0"></canvas>
          </div>
        </v-col>
      </v-row>

      <v-row wrap>
        <v-col cols="12" md="6" align="center">
          <div :style="ZoneProfileStyle">
            <canvas id="ZoneProfileChart" style="height: 0"></canvas>
          </div>
        </v-col>

        <v-col cols="12" md="6" align="center">
          <div :style="ZoneMemberImageStyle">
            <canvas id="ZoneMemberImageChart" style="height: 0"></canvas>
          </div>
        </v-col>
      </v-row>

      <v-row wrap>
        <v-col cols="12" md="6" align="center">
          <div :style="AllZoneProfileStyle">
            <canvas id="AllZoneMemberImageChart" style="height: 0"></canvas>
          </div>
        </v-col>

        <v-col cols="12" md="6" align="center">
          <div :style="AllZoneMemberImageStyle">
            <canvas id="AllZoneProfileChart" style="height: 0"></canvas>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <p></p>
    <br />
    <v-container class="py-0" v-if="tableData1.length > 0">
      <h3>{{ tableOptions1.TableTitle }}</h3>
      <br />
      <v-row wrap>
        <v-col align="center" cols="12" md="12">
          <v-btn
            v-if="tableOptions1.DownloadFlag"
            color="info"
            @click.prevent="
              generateExcel(
                tableData1,
                tableOptions1.ExcelFields,
                tableOptions1.ExcelFileName,
                'My Worksheet'
              )
            "
            class="mr-4"
            >Download</v-btn
          >
        </v-col>
        <p></p>
      </v-row>
      <br />
      <v-row wrap>
        <v-col align="center" cols="12" md="12">
          <h4>{{ tableData1.length }} records found.</h4>
        </v-col>
      </v-row>
      <v-row wrap>
        <v-col align="center" cols="12" md="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <br />
          <v-data-table
            class="elevation-1"
            v-model="selected1"
            :search="search"
            :item-key="tableOptions1.ItemKey"
            :single-select="tableOptions1.SingleSelectFlag"
            :show-select="tableOptions1.ShowSelectFlag"
            :headers="tableColumns1"
            :items="tableData1"
            :items-per-page="tableOptions1.ItemsPerPage"
            :footer-props="tableOptions1.FooterProps"
          ></v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  mixins: [common],
  name: "DashboardDashboard",
  data() {
    return {
      StartupLoadingFlag: false,
      LoadingFlag: false,
      SearchFlag: false,

      LomProfileChart: "",
      LomMemberImageChart: "",
      AllLomProfileChart: "",
      AllLomMemberImageChart: "",

      LomProfileStyle: "",
      LomMemberImageStyle: "",
      AllLomProfileStyle: "",
      AllLomMemberImageStyle: "",

      ZoneProfileChart: "",
      ZoneMemberImageChart: "",
      AllZoneProfileChart: "",
      AllZoneMemberImageChart: "",

      ZoneProfileStyle: "",
      ZoneMemberImageStyle: "",
      AllZoneProfileStyle: "",
      AllZoneMemberImageStyle: "",

      rows: {},

      AreaCodeRules: [],
      AreaCode: "",
      AreaCodeOptions: [],
      AreaCodeOptionsLoading: false,

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      RegionCodeRules: [],
      RegionCode: "",
      RegionCodeOptions: [],
      RegionCodeOptionsLoading: false,

      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      selected1: [],
      search: "",
      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
    };
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.StartupLoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        this.CurrentYearId = CurrentJciYearId;

        this.getAreaCodeOptions();
      }
    },
    AreaCodeOptions: function () {
      console.log("watch AreaCodeOptions");
      this.AreaCodeOptionsLoading = false;
      this.StartupLoadingFlag = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    RegionCodeOptions: function () {
      console.log("watch RegionCodeOptions");
      this.RegionCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");

      this.StartupLoadingFlag = false;

      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "member_statistics",
        Action: "dashboard",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getAreaCodeOptions() {
      console.log("getAreaCodeOptions called");
      this.AreaCodeOptionsLoading = true;
      this.StartupLoadingFlag = true;
      var selectbox1_source = "AreaCode";
      var selectbox1_destination = "AreaCodeOptions";
      var selectbox1_url = "api/year-wise-lom/area-options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
        YearCode: this.JciYearCode,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.tableData1 = [];
      this.ZoneCode = "";
      this.RegionCode = "";
      this.LomCode = "";
      this.ZoneCodeOptions = [];
      this.RegionCodeOptions = [];
      this.LomCodeOptions = [];

      var yearCode = this.JciYearCode;
      var areaCode = this.AreaCode;
      console.log("yearCode=" + yearCode + ", areaCode=" + areaCode);
      if (yearCode != "" && areaCode != "") {
        this.ZoneCode = "";
        this.ZoneCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 5,
          AreaCode: areaCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getRegionCodeOptions() {
      console.log("getRegionCodeOptions called");
      this.tableData1 = [];
      this.RegionCode = "";
      this.LomCode = "";
      this.RegionCodeOptions = [];
      this.LomCodeOptions = [];

      var YearCode = this.JciYearCode;
      var AreaCode = this.AreaCode;
      var ZoneCode = this.ZoneCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", AreaCode=" +
          AreaCode +
          ", ZoneCode=" +
          ZoneCode
      );
      if (YearCode != "" && AreaCode != "" && ZoneCode != "") {
        this.RegionCode = "";
        this.RegionCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "RegionCode";
        var selectbox1_destination = "RegionCodeOptions";
        var selectbox1_url = "api/year-wise-lom/region-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: YearCode,
          AreaCode: AreaCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      this.tableData1 = [];
      this.LomCode = "";
      this.LomCodeOptions = [];

      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      var RegionCode = this.RegionCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", ZoneCode=" +
          ZoneCode +
          ", RegionCode=" +
          RegionCode
      );
      if (YearCode != "" && ZoneCode != "" && RegionCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
          RegionCode: RegionCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        if (RegionCode == "") {
          message += "Region should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    clearCharts() {
      // clear all charts

      if (this.AllZoneProfileChart) {
        this.AllZoneProfileChart.destroy();
      }
      if (this.AllZoneMemberImageChart) {
        this.AllZoneMemberImageChart.destroy();
      }
      if (this.ZoneMemberImageChart) {
        this.ZoneMemberImageChart.destroy();
      }
      if (this.ZoneProfileChart) {
        this.ZoneProfileChart.destroy();
      }
      if (this.LomMemberImageChart) {
        this.LomMemberImageChart.destroy();
      }
      if (this.LomProfileChart) {
        this.LomProfileChart.destroy();
      }
    },
    searchForm() {
      console.log("searchForm called");

      console.log("ZoneCode=" + this.ZoneCode);

      // clear all chart
      this.clearCharts();

      this.getAllZoneRecords();

      if (this.ZoneCode != "") {
        this.getZoneRecords();
      }
      if (this.ZoneCode != "" || this.RegionCode != "") {
        this.getAllLomRecords();
      }
      if (this.LomCode != "") {
        this.getLomRecords();
      }
    },
    getAllZoneRecords() {
      console.log("getAllZoneRecords called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/reports/member_statistics/zone_wise";
      var upload = {
        UserInterface: 2,
        YearCode: this.JciYearCode,
        AreaCode: this.AreaCode,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.LoadingFlag = true;
      this.SearchFlag = true;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);

          if (flag == 1) {
            thisIns.AllZoneProfileStyle = records.ZoneProfileStyle;
            thisIns.AllZoneMemberImageStyle = records.ZoneMemberImageStyle;

            if (records.ZoneProfileFlag) {
              thisIns.AllZoneProfileChart = new Chart(
                document.getElementById("AllZoneProfileChart"),
                {
                  type: records.ZoneProfileChart.chartType,
                  data: records.ZoneProfileChart.chartData,
                  options: records.ZoneProfileChart.chartOptions,
                  showTooltips: false,
                  onAnimationComplete: function () {
                    var ctx = this.chart.ctx;
                    ctx.font = this.scale.font;
                    ctx.fillStyle = this.scale.textColor;
                    ctx.textAlign = "center";
                    ctx.textBaseline = "bottom";

                    this.datasets.forEach(function (dataset) {
                      dataset.bars.forEach(function (bar) {
                        ctx.fillText(bar.value, bar.x, bar.y - 5);
                      });
                    });
                  },
                }
              );
            }

            if (records.ZoneMemberImageFlag) {
              thisIns.AllZoneMemberImageChart = new Chart(
                document.getElementById("AllZoneMemberImageChart"),
                {
                  type: records.ZoneMemberImageChart.chartType,
                  data: records.ZoneMemberImageChart.chartData,
                  options: records.ZoneMemberImageChart.chartOptions,
                }
              );
            }
          }
        })
        .catch(function (error) {
          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;
          console.log("error=" + error);
          thisIns.toast("error", error);
        });
    },
    getZoneRecords() {
      console.log("getZoneRecords called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/reports/member_statistics/zone_wise";
      var upload = {
        UserInterface: 1,
        YearCode: this.JciYearCode,
        AreaCode: this.AreaCode,
        ZoneCode: this.ZoneCode,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.LoadingFlag = true;
      this.SearchFlag = true;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);

          if (flag == 1) {
            thisIns.ZoneProfileStyle = records.ZoneProfileStyle;
            thisIns.ZoneMemberImageStyle = records.ZoneMemberImageStyle;

            if (records.ZoneProfileFlag) {
              thisIns.ZoneMemberImageChart = new Chart(
                document.getElementById("ZoneMemberImageChart"),
                {
                  type: records.ZoneMemberImageChart.chartType,
                  data: records.ZoneMemberImageChart.chartData,
                  options: records.ZoneMemberImageChart.chartOptions,
                }
              );
            }

            if (records.ZoneMemberImageFlag) {
              thisIns.ZoneProfileChart = new Chart(
                document.getElementById("ZoneProfileChart"),
                {
                  type: records.ZoneProfileChart.chartType,
                  data: records.ZoneProfileChart.chartData,
                  options: records.ZoneProfileChart.chartOptions,
                }
              );
            }
          }
        })
        .catch(function (error) {
          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;
          console.log("error=" + error);
          thisIns.toast("error", error);
        });
    },
    getAllLomRecords() {
      console.log("getAllLomRecords called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/reports/member-statistics/lom-table";
      var upload = {
        UserInterface: 1,
        YearCode: this.JciYearCode,
        AreaCode: this.AreaCode,
        ZoneCode: this.ZoneCode,
        RegionCode: this.RegionCode,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.LoadingFlag = true;
      this.SearchFlag = true;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          var options = records.TableOptions;

          if (flag == 1) {
            thisIns.tableColumns1 = records.TableHeader;
            thisIns.tableOptions1 = options;
            thisIns.tableData1 = records.TableData;
          }
        })
        .catch(function (error) {
          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;
          console.log("error=" + error);
          thisIns.toast("error", error);
        });
    },
    getLomRecords() {
      console.log("getLomRecords called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/reports/member-statistics/lom-chart";
      var upload = {
        UserInterface: 1,
        YearCode: this.JciYearCode,
        AreaCode: this.AreaCode,
        ZoneCode: this.ZoneCode,
        RegionCode: this.RegionCode,
        LomCode: this.LomCode,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.LoadingFlag = true;
      this.SearchFlag = true;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);

          if (flag == 1) {
            thisIns.LomProfileStyle = records.LomProfileStyle;
            thisIns.LomMemberImageStyle = records.LomMemberImageStyle;

            if (records.LomMemberImageFlag) {
              thisIns.LomMemberImageChart = new Chart(
                document.getElementById("LomMemberImageChart"),
                {
                  type: records.LomMemberImageChart.chartType,
                  data: records.LomMemberImageChart.chartData,
                  options: records.LomMemberImageChart.chartOptions,
                }
              );
            }

            if (records.LomMemberImageFlag) {
              thisIns.LomProfileChart = new Chart(
                document.getElementById("LomProfileChart"),
                {
                  type: records.LomProfileChart.chartType,
                  data: records.LomProfileChart.chartData,
                  options: records.LomProfileChart.chartOptions,
                }
              );
            }
          }
        })
        .catch(function (error) {
          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;
          console.log("error=" + error);
          thisIns.toast("error", error);
        });
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#dashboard-lom-president {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 15px;
  }
  .animate-button1 {
    display: inline-block;
    margin: 0 0.5rem;
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .president-details-table {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.01px;
    font-weight: 400;
    font-style: normal;
  }
  .lom-details-table {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.01px;
    font-weight: 400;
    font-style: normal;
  }
}
</style>